.row {
    background: white;
}

.forgot {
    display: inline-block;
    text-decoration: none;
}

.forgot::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: rgb(148, 109, 255);
    transition: width .3s;
}

.forgot:hover::after {
    width: 100%;
    /* transition: width .3s; */
}

.crossfade>figure {
    animation: imageAnimation 30s linear infinite 0s;
    backface-visibility: hidden;
    background-size: cover;
    /* background-position: center center; */
    color: transparent;
    height: 100%;
    width: 100%;
    /* left: 0px; */
    /* right: 0px; */
    opacity: 0;
    position: absolute;
    top: 0px;
    /* width: 800px; */
    z-index: 0;
}

.crossfade>figure:nth-child(1) {
    background-image: url('./BetaImg/BETA1.jpg');
}

.crossfade>figure:nth-child(2) {
    animation-delay: 6s;
    background-image: url('./BetaImg/image2.jpg');
}

.crossfade>figure:nth-child(3) {
    animation-delay: 12s;
    background-image: url('./BetaImg/BETA1.jpg');
}

.crossfade>figure:nth-child(4) {
    animation-delay: 18s;
    background-image: url('./BetaImg/image2.jpg');
}

.crossfade>figure:nth-child(5) {
    animation-delay: 24s;
    background-image: url('./BetaImg/BETA1.jpg');
}

@keyframes imageAnimation {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
    }

    8% {
        animation-timing-function: ease-out;
        opacity: 1;
    }

    17% {
        opacity: 1
    }

    25% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@media only screen and (max-width: 600px) {
    .crossfade {
      display: none;    
    }
}

